<template>
  <div class="main">
    <div class="backPage" @click="$router.go(-1)">返回</div>
    <heand :tagnum="tagnum"></heand>
    <div class="chuangx_ziytop">
      <dl>
        <dt>
          <!-- <img src="../../assets/industrial/taiyuanligong.png" alt /> -->
          <span>{{details.name}}</span>
        </dt>
        <dd>
          <ul>
            <li>主管部门：{{details.charge}}</li>
            <li>高校类型：{{details.category}}</li>
            <li>创办时间：{{details.buildate}}</li>
            <li>办学性质：{{details.nature}}</li>
            <li>学校地址：{{details.address}}</li>
          </ul>
        </dd>
      </dl>
    </div>

    <div class="chuangx_fffbox">
      <h1 class="chuang_tit">学校简介</h1>
      <div class="chuangx_jsh">
        <p>{{details.introduct}}</p>
      </div>
    </div>

    <div class="chuangx_fffbox">
      <h1 class="chuang_tit">基本信息</h1>
      <div class="school_ul">
        <ul>
          <li>
            <strong>学校名称：</strong>
            <span>{{details.name}}</span>
          </li>
          <li>
            <strong>学校类别：</strong>
            <span>{{details.clas}}</span>
          </li>
          <li>
            <strong>院校专业：</strong>
            <span>{{details.major}}</span>
          </li>
          <li>
            <strong>院系设置：</strong>
            <span>{{details.faculty}}</span>
          </li>
          <li>
            <strong>学校代码：</strong>
            <span>{{details.ccode}}</span>
          </li>
        </ul>
      </div>
      <div class="school_ul">
        <ul>
          <li>
            <strong>创办时间：</strong>
            <span>{{details.buildate}}</span>
          </li>
          <li>
            <strong>学校特色：</strong>
            <span>{{details.feature}}</span>
          </li>
          <li>
            <strong>学校地址：</strong>
            <span>{{details.address}}</span>
          </li>
          <li>
            <strong>办学性质：</strong>
            <span>{{details.nature}}</span>
          </li>
          <li>
            <strong>研究方向：</strong>
            <span>{{details.research}}</span>
          </li>
        </ul>
      </div>
    </div>

    <div class="chuangx_fffbox">
      <h1 class="chuang_tit">地图</h1>
      <div class="chuangx_map">
        <baidu-map
          class="bm-view"
          :center="details.jwd"
          :zoom="zoom"
          :double-click-zoom="double"
          :dragging="dragging"
           :scroll-wheel-zoom="true"
        >
          <bm-marker :position="center" :dragging="false"></bm-marker>
        </baidu-map>
      </div>
    </div>
  </div>
</template>

<script>
import { catongid } from "@/api/index";
export default {
  name: "chdetsils1",
  data() {
    return {
      tagnum: 2,
      id: "",
      zoom: 15,
      double: false,
      dragging: false,
      details: [],
      center: { lat: "", lng: "" }
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.cateone = this.$route.query.cateone;
    this.getdetails();
  },
  methods: {
    async getdetails() {
      const res = await catongid({
        id: this.id,
        cateone: 1
      });
      this.center.lat = res.jwd.lat;
      this.center.lng = res.jwd.lng;
      this.details = res;
    }
  }
};
</script>

<style lang="less" scoped>
.main {
  background: #f2f4f7;
  overflow: hidden;
  zoom: 1;
  font-family: "PingFangSC";
}
.chuangx_ziytop {
  margin: 20px auto;
  background: url(../../assets/industrial/chuangx_top6.png) no-repeat;
  width: 1360px;
  height: 200px;
  padding: 40px;
  color: #fff;

  dl {
    overflow: hidden;
    zoom: 1;
    dt {
      font-size: 36px;
      font-weight: bold;
      float: left;
      line-height: 120px;
      img {
        margin-top: 25px;
      }
    }
    dd {
      float: right;
      width: 500px;
      padding-top: 10px;
    }
    dd ul {
      font-size: 16px;
      text-align: left;
      line-height: 38px;
      list-style: none;
      li {
        margin-left: 40px;
        float: left;
        font-weight: bold;
        width: 200px;
      }
      li:last-child {
        width: 650px;
      }
    }
  }
}

.chuangx_fffbox {
  width: 1360px;
  overflow: hidden;
  zoom: 1;
  background: #fff;
  text-align: left;
  margin: 0 auto 20px auto;
  padding-bottom: 20px;

  .chuang_tit {
    font-size: 18px;
    color: #333;
    padding: 16px;
  }
  .chuangx_jsh {
    font-size: 15px;
    line-height: 26px;
    padding: 0 16px;

    p {
      text-indent: 24px;
    }
  }
  .chuangx_map {
    padding: 0 16px;
    height: 300px;
    .bm-view {
      width: 100%;
      height: 100%;
    }
  }

  .school_ul {
    float: left;
    width: 650px;
    min-height: 270px;
    padding-bottom:20px;
    background: linear-gradient(180deg, #ffffff, #ffebdd);
    margin-left: 20px;
    ul {
      margin: 30px;
      li {
        overflow: hidden;
        zoom: 1;
        font-size: 15px;
        line-height: 38px;
        strong {
          display: block;
          width: 85px;
          float: left;
          color: #333;
          font-weight: normal;
        }
        span {
          display: block;
          width: 480px;
          float: left;
          color: #fe6a00;
        }
      }
      .school_li {
        strong {
          width: 116px;
        }
        span {
          width: 250px;
        }
      }
    }
  }
}
</style>